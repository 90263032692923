import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ScrollOut from "scroll-out";

export default class Projects extends React.Component {
  componentDidMount() {
    ScrollOut();
  }

  render() {
    return (
      <Container className="kn-project-container">
        <Row className="kn-project-card-row">
          <Col className="kn-project-card-col">
            <Card data-scroll className="bg-light text-dark">
              <Card.Img
                src="./images/website-architecture.png"
                alt="Card image"
              />
              <Card.ImgOverlay>
                <Card.Title>
                  <a
                    href="https://github.com/nainkunal933/kunalnain.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    kunalnain.com
                  </a>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
        <Row className="kn-project-card-row">
          <Col className="kn-project-card-col">
            <Card data-scroll style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title>FireMap</Card.Title>
                <Card.Text>
                  A Google Map application that shows the nearby fire hazards.
                </Card.Text>
                <a
                  href="https://github.com/nainkunal933/FireMap"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="primary">Explore</Button>
                </a>
              </Card.Body>
            </Card>
          </Col>

          <Col className="kn-project-card-col">
            <Card data-scroll style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title>MyGEO</Card.Title>
                <Card.Text>
                  A serverless function that gives back a users geo coordinates
                  using the Google Geocoding API.
                </Card.Text>
                <a href="/my-geo">
                  <Button variant="primary">Demo</Button>
                </a>
              </Card.Body>
            </Card>
          </Col>

          <Col className="kn-project-card-col">
            <Card data-scroll style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title>Agile Sprint Success Prediction</Card.Title>
                <Card.Text>
                  An application that predicts the likelihood of sprint success
                  based on several parameters.
                </Card.Text>
                <a
                  href="https://github.com/nainkunal933/sprint-success-prediction"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="primary">Explore</Button>
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="kn-project-card-row">
          <Col className="kn-project-card-col">
            <Card data-scroll style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title>Sub Space Clustering Algorithm</Card.Title>
                <Card.Text>
                  A machine learning algorithm that implements sub space
                  clustering.
                </Card.Text>
                <Card.Link
                  href="https://github.com/nainkunal933/subspace-clustering"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub Repo
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>

          <Col className="kn-project-card-col">
            <Card data-scroll style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title>Arduino Smart Irrigation</Card.Title>
                <Card.Text>
                  C++ code for Arduino to interface with sensors and python for
                  analyzing the data.
                </Card.Text>
                <Card.Link
                  href="https://github.com/nainkunal933/ArduinoSmartIrrigationController"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub Repo
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
