import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BrowserRouter as Route, Link } from "react-router-dom";
import MyGEO from "./MyGEO";

export default class ProjectsLanding extends Component {
  render() {
    return (
      <Container className="kn-full-height-container">
        <Row className="kn-project-card-row">
          <Col className="kn-project-card-col">
            <Card>
              <Card.Header>Featured</Card.Header>
              <Card.Body>
                <Card.Title>MyGEO</Card.Title>
                <Card.Text>
                  A serverless function that gives back a users geo coordinates
                  using the Google Geocoding API.
                </Card.Text>
                <Link to="/my-geo">
                  <Button variant="primary">Demo</Button>
                </Link>
                <Route path="/my-geo" exact component={MyGEO} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="kn-project-card-row">
          <Col className="kn-project-card-col">
            <Card className="text-center" style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title>Explore more on Github</Card.Title>
                <a
                  href="https://github.com/nainkunal933"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="primary">Explore</Button>
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
