import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";

export default class Certifications extends React.Component {
  render() {
    return (
      <Container fluid className="kn-badge-container">
        <Row>
          <Col xs="0" sm="1" md="2"></Col>
          <Col xs="12" sm="5" md="4" className="kn-badge-col">
            <a href="https://www.certmetrics.com/amazon/public/badge.aspx?i=9&t=c&d=2019-06-18&ci=AWS00939083">
              <Image
                src="./images/certifications/cloud-practitioner-badge.png"
                width="220"
                height="220"
              />
            </a>
          </Col>

          <Col xs="12" sm="5" md="4" className="kn-badge-col">
            <a href="https://www.certmetrics.com/amazon/public/badge.aspx?i=1&t=c&d=2019-08-08&ci=AWS00939083">
              <Image src="./images/certifications/solutions-architect-associate-badge.png" />
            </a>
          </Col>
          <Col xs="0" sm="1" md="2"></Col>
        </Row>
      </Container>
    );
  }
}
