import React, { Component } from "react";
import Introduction from "./Introduction";
import Divider from "./Divider";
import WorkExperience from "./WorkExperience";
import Projects from "./Projects";
import Certifications from "./Certifications";

export default class Home extends Component {
  render() {
    return (
      <div>
        <Introduction />
        <Divider title="Work Experience" />
        <WorkExperience />
        <Divider title="Projects" />
        <Projects />
        <Divider title="Certifications" />
        <Certifications />
      </div>
    );
  }
}
