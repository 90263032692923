import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ScrollOut from "scroll-out";

export default class Divider extends React.Component {
  componentDidMount() {
    ScrollOut({
      /* options */
    });
    ScrollOut();
  }
  render() {
    return (
      <Container fluid className="kn-divider">
        <Row>
          <Col>
            <h4 data-scroll>{this.props.title}</h4>
          </Col>
        </Row>
      </Container>
    );
  }
}
