import React from "react";
import "./App.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Home from "./components/Home";
import ProjectsLanding from "./components/ProjectsLanding";
import MyGEO from "./components/MyGEO";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar
          sticky="top"
          collapseOnSelect
          className="navbar kn-header"
          expand="lg"
        >
          <Navbar.Brand id="kn-logo" href="/">
            <Image src="/images/logo.png" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav"
          >
            <Nav>
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/projects">
                Projects
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <Switch>
          <Route path={["/", "/index", "/index.html"]} exact component={Home} />
          <Route
            path={["/projects", "/projects.html"]}
            exact
            component={ProjectsLanding}
          />
          <Route path={["/my-geo", "/my-geo.html"]} exact component={MyGEO} />
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
