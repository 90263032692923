import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
export default class Footer extends React.Component {
  render() {
    return (
      <Container fluid className="kn-footer-container">
        <Container fluid className="kn-footer-container-1">
          <Row className="kn-footer-row">
            <Col className="kn-footer-col">
              Designed &amp; Built by Kunal Nain
            </Col>
          </Row>
        </Container>
        <Container fluid className="kn-footer-container-2">
          <Row className="kn-footer-row">
            <Col className="kn-footer-col">
              <a href="https://www.privacypolicies.com/terms/view/237ba97c02bf02bdfc5548e97e81942b">
                Terms of Condition
              </a>
            </Col>
            <Col className="kn-footer-col">
              <a href="https://www.privacypolicies.com/privacy/view/2f199a54845ea7f243f9dbd49d469cc3">
                Privacy Policy
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}
