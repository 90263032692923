import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Jumbotron from "react-bootstrap/Jumbotron";
import Typed from "typed.js";
import Particles from "react-particles-js";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

export default class Introduction extends React.Component {
  componentDidMount() {
    var options = {
      strings: ["I am a Software Engineer", "I am a Software Engineer"],
      typeSpeed: 40,
      smartBackSpace: true,
      backSpeed: 40,
      showCursor: false,
    };

    this.typed = new Typed(this.el, options);
  }

  render() {
    const StyledLink = styled.a`
      color: #ffffff;
      &:hover {
        color: #b2b3b4;
      }
    `;

    const StyledIconContainer = styled.p`
      margin-top: 1rem;
    `;

    return (
      <Container className="kn-container" fluid>
        <Particles
          className="kn-canvas"
          params={{
            particles: {
              number: {
                value: 80,
                density: {
                  enable: true,
                  value_area: 800,
                },
              },
            },
          }}
        />

        <Row className="kn-introduction-row">
          <Col sm="2" lg="2"></Col>
          <Col sm="8" lg="4">
            <Jumbotron className="kn-jumbotron">
              <h3>Hi! My name is</h3>
              <h1>Kunal Nain</h1>
              <h2>
                <span
                  className="typed-playground"
                  ref={(el) => {
                    this.el = el;
                  }}
                >
                  {" "}
                </span>
              </h2>
              <StyledIconContainer>
                <span>
                  <StyledLink
                    id="kn-gmail"
                    href="mailto:kunalnain1811@gmail.com"
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      size="2x"
                    ></FontAwesomeIcon>
                  </StyledLink>
                </span>
                <StyledLink
                  id="kn-gmail"
                  href="https://github.com/nainkunal933"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faGithub} size="2x" />
                </StyledLink>
                <StyledLink
                  id="kn-gmail"
                  href="https://www.linkedin.com/in/knain/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
                </StyledLink>
              </StyledIconContainer>
            </Jumbotron>
          </Col>
          <Col sm="6" lg="4" className="kn-headshot-container">
            <Image src="./images/kunal_headshot.jpg" roundedCircle />
          </Col>
          <Col sm="2" lg="2"></Col>
        </Row>
      </Container>
    );
  }
}
