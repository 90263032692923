import React from "react";
import Container from "react-bootstrap/Container";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Work from "@material-ui/icons/Work";

export default class WorkExperience extends React.Component {
  render() {
    return (
      <Container fluid className="kn-work-ex">
        <VerticalTimeline>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="May 2023 - Present"
            iconStyle={{ background: "#6F83EE", color: "#fff" }}
            icon={<Work />}
          >
            <h4 className="vertical-timeline-element-title">
              Senior Software Engineer
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
              Becton Dickinson
            </h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Jul 2021 - May 2023"
            iconStyle={{ background: "#2DCE89", color: "#fff" }}
            icon={<Work />}
          >
            <h4 className="vertical-timeline-element-title">
              Software Development Engineer
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
              Amazon Web Services
            </h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Aug 2020 - Jul 2021"
            iconStyle={{ background: "#6F83EE", color: "#fff" }}
            icon={<Work />}
          >
            <h4 className="vertical-timeline-element-title">
              Software Development Engineer 1
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
              Mitchell International
            </h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="May 2019 - July 2020"
            iconStyle={{ background: "#2DCE89", color: "#fff" }}
            icon={<Work />}
          >
            <h4 className="vertical-timeline-element-title">
              Senior Software Engineer
            </h4>
            <h5 className="vertical-timeline-element-subtitle">M8 Systems</h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="July 2018 - May 2019"
            iconStyle={{ background: "#6F83EE", color: "#fff" }}
            icon={<Work />}
          >
            <h4 className="vertical-timeline-element-title">
              Software Consultant
            </h4>
            <h5 className="vertical-timeline-element-subtitle">M8 Systems</h5>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </Container>
    );
  }
}
