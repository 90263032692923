import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";

export default class MyGEO extends React.Component {
  state = {
    station: "",
    latitude: "None",
    longitude: "None",
  };

  handleChange = (event) => {
    this.setState({ station: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const station = this.state.station;

    console.log(station);
    axios
      .post(
        `https://fzgl8uapf3.execute-api.us-east-1.amazonaws.com/default/my-geo`,
        { station }
      )
      .then((res) => {
        console.log(res);
        this.setState({
          latitude: res.data.body.lat,
          longitude: res.data.body.lng,
        });
      });
  };

  render() {
    return (
      <Container className="kn-full-height-container">
        <Row className="kn-geo-row">
          <Col>
            <h1 className="kn-h1">MyGEO</h1>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group>
                <Form.Label>
                  MyGEO is an application that converts an address into geo
                  coordinates using the Google Geocoordinates API.
                </Form.Label>
                <Form.Control
                  placeholder="123 Street Name, City, Country, Zip Code"
                  onChange={this.handleChange}
                />
                <Form.Text className="text-muted">
                  I'll never share your address with anyone else.
                </Form.Text>
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>
              Latitude ={" "}
              <span className="text-success">{this.state.latitude}</span>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>
              Longitude ={" "}
              <span className="text-success">{this.state.longitude}</span>
            </h4>
          </Col>
        </Row>
      </Container>
    );
  }
}
